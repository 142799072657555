import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig, { socket } from "src/config/APICongig";
import { calculateTimeLeft } from "src/views/auth/otpVerify/timer";
import moment from "moment";
// import ApiConfig from "src/config/APICongig";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    // axios.defaults.headers.common.Authorization = `Creattur ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());

  //   const [userData, setUserBalanceData] = useState({});
  const [userData, setUserData] = useState({});

  const [loader, setLoader] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [endTime, setEndtime] = useState();
  const [wallet, setWallet] = useState([]);
  const [view, setView] = useState([]);

  const [fromData, setFromData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [toData, setToData] = useState();

  const [coinName, setCoinName] = useState("all");

  const [currentvalue, setCurrentValue] = useState("all");
  const [page, setPage] = useState(1);

  const [value, setValue] = useState(0);

  const [timeLeft, setTimeLeft] = useState();
  const [pagesCount, setPagesCount] = useState(1);
  const [bankData, setBankDataList] = useState([]);
  const [cmcResults, setCMCResults] = useState([]);
  const [iNRPriceinUSD, setINRPriceinUSD] = useState(1);
  const [chatMessageData, setChatMessageData] = useState();

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  let data = {
    userLoggedIn: isLogin,
    userData,
    iNRPriceinUSD,
    kycData,
    cmcResults,
    setEndtime,
    setTimeLeft,
    setToData,
    setFromData,
    setCoinName,
    setBankDataList,
    isLoading,
    bankData,
    coinName,
    wallet,
    timeLeft,
    endTime,
    fromData,
    pagesCount,
    toData,
    view,
    page,
    setPage,
    handlereset: () => handlereset(),
    // getProfileHandler: () => getProfileHandler(),

    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  const handlereset = () => {
    setCoinName("");
    setFromData();
    setToData();
  };

  // const getProfileHandler = async (token) => {
  //   console.log(token,"tokeennnnnnnnn")
  //   if (token) {
  //     console.log("");
  //   } else {
  //     window.localStorage.removeItem("token");
  //     setLoader(false);
  //   }
  // };
  // <<<<<<< HEAD
  //   // console.log("userData--", userData);
  // =======

  // >>>>>>> 6f1122ea02dfdde0e0d6a39c0b0308bf068743c6

  // useEffect(() => {
  //   if (window.localStorage.getItem("token")) {
  //     getProfileHandler(window.localStorage.getItem("token"));
  //   }
  // }, [window.localStorage.getItem("token")]);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
