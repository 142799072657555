//sachin
// const url = "https://nodepune-classifiedads.mobiloitte.io/api/v1";

const url = "https://nodepune-datastorage.mobiloitte.io/api/v1"
const user = `${url}/user`;

const ApiConfig = {
  connectWallet:`${user}/connectWallet`,
  viewUser: `${user}/viewUser`,
  updateProfile: `${user}/updateProfile`,
  placeOrder:`${user}/placeOrder`,
  subscribeToNewsLetter: `${user}/subscribeToNewsLetter`,
};

export default ApiConfig;
