import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  FormHelperText,
  Dialog,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Logo from "src/component/Logo";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import moment from "moment";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";
import axios from "axios";
import ApiConfig from "src/config/APICongig";
const useStyles = makeStyles((theme) => ({
  dialogMain: {
    background: "rgba(0, 0, 0, 0.6)",

    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(74 74 74 / 50%)",
    },
    "& .MuiDialog-paperWidthSm": {
      background: "#0C576C",
      width: "100%",
      maxWidth: "392px",
      padding: "75px",
    },
  },
  TextBox: {
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
      borderRadius: "2.77px",
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "#ffffff !important",
    },
    "& .MuiOutlinedInput-input": {
      height: "22px",
    },
  },
  heading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "14px",
    color: "#FFFFFF",
    margin: "0",
    marginBottom: "12px",
  },
  subHeading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    color: "rgba(255, 255, 255, 0.6)",
  },
  headingDiv: {
    textAlign: "center",
  },

  rememberMe: {
    "& .MuiTypography-body1": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "rgba(255, 255, 255, 0.6)",
    },
  },
  forgotPassword: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#D39B2D",
  },
  submitButton: {
    width: "100%",
    height: "44px",
    background: "#D39B2D",
    borderRadius: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#FFFFFF",
  },
}));
export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useContext(AuthContext);
  const [isLoading, setLoader] = useState(false);
  const [isloading, setloader] = useState(false);
  const resetotphandle = async (values) => {
    try {
      setloader(true);

      const res = await axios({
        method: "PUT",
        url: ApiConfig.resendOTP,
        data: {
          email: window.localStorage.getItem("email"),
        },
      });
      if (res.data.responseCode === 200) {
        toast.success("OTP has been sent to your registered email address.");
        setloader(false);
    
        auth.setEndtime(moment().add(3, "m").unix());

      } else {
        setloader(false);
        toast.warn(res.data.message);
      }
    } catch (error) {
      setloader(false);
      if (error.response) {
        toast.error("Please enter the correct Email");
      } else {
        toast.error(error.message);
      }
    }
  };

  const handleFormSubmit = async (values) => {
    const id = window.localStorage.getItem("id")
    try {
      setLoader(true);

      const res = await axios({
        method: "POST",
        url: ApiConfig.verifyOTP,

        data: {
          _id: id,
          otp: values.otp.toString(),
        },
      });
      if (res.data.responseCode === 200) {
        toast.success("Account created successfully.");
        setLoader(false);
        props.handleOtpModal();

      } else if (res.data.responseCode === 205) {
        setLoader(false);
        toast.warn(res.data.message);
      } else {
        setLoader(false);
        toast.warn(res.data.message);
      }
    } catch (error) {
     
      setLoader(false);
      if (error.response) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error(error.responseMessage);
      }
    }
  };
  const email = window.localStorage.getItem("email");
  const minute = auth.timeLeft?.minutes?.toString();
  const second = auth.timeLeft?.seconds?.toString();

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleOtpModal}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialogMain}
      >
        <Box>
          <Logo style={{ marginBottom: "30px", width: "139px" }} />
        </Box>
        <Box className={classes.headingDiv}>
          <Typography className={classes.heading}>Hello Again!</Typography>
          <Typography className={classes.subHeading}>
            Easily buy and sell items with just a few clicks
          </Typography>
        </Box>
        <Box>
          <Formik
                onSubmit={(values) => handleFormSubmit(values)}
                initialValues={{
                  otp: "",
                }}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={yep.object().shape({
                  otp: yep
                    .string()
                    .required("OTP is required")
                    .matches(/^[0-9]*$/, "Must be a valid OTP")
                    .max(6, "Should not exceeds 4 digits")
                    .min(6, "Must be only 4 digits"),
                })}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
            }) => (
              <Form>
                <Grid item style={{ marginTop: "1.5rem" }}>
                  <TextField
                    label="OTP"
                 
                    variant="outlined"
                    fullWidth
                    id="otp outlined-basic"
                    size="small"
                    type="number"
                    onWheel={() => document.activeElement.blur()}
                    name="otp"
                    value={values.otp}
                    className="textFeilds"
                    onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      error={Boolean(touched.otp && errors.otp)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                    onKeyPress={(event) => {
                        if (event?.key === "-" || event?.key === "+") {
                          event.preventDefault();
                        }
                      }}
                  />
                  <FormHelperText
                    error
                    style={{ fontSize: "12px", fontFamily: "Roboto" }}
                  >
                   {touched.otp && errors.otp}
                  </FormHelperText>
                </Grid>
                <Box style={{ display: "flex", justifyContent: "end" }}>
                     {auth.timeLeft?.minutes > 0 || auth.timeLeft?.seconds > 0 ? (
                      <>
                        <Box>
                          <Typography
                            variant="body1"
                            style={{
                              color: "red",
                              fontSize: "12px",
                              fontWeight: "800",
                              fontFamily: "Roboto",
                            }}
                          >
                          
                            {minute.length > 1 ? minute : "0" + minute} :{" "}
                            {second.length > 1 ? second : "0" + second}
                          </Typography>{" "}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Button
                          style={{
                            color: "red",
                            fontFamily: "Roboto",
                            fontWeight: "800",
                          }}
                          // fullWidth
                          onClick={() => {
                            resetotphandle();
                          }}
                          disabled={auth.timeLeft && auth.timeLeft.seconds > 0}
                        >
                          {" "}
                          Resend OTP{" "}
                        </Button>{" "}
                        &nbsp;
                      </>
                    )}{" "}
                  </Box>

                <Grid>
                  <Box className={classes.SubmitBtnBox} mt={2}>
                    <Button
                      type="submit"
                      className={classes.submitButton}
                      disabled={isloading}
                      // onClick={() => history.push("/dashboard")}
                    >
                      Verify
                      {isloading && <ButtonCircularProgress />}
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </div>
  );
}
