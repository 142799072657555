import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Link,
  FormHelperText,
  FormControl,
  InputAdornment,
  IconButton,
  Dialog,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import Logo from "src/component/Logo";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { useHistory, Link as RouterComponent } from "react-router-dom";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import ApiConfig from "src/config/APICongig";
import { AuthContext } from "src/context/Auth";
import axios from "axios";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  dialogMain: {
    background: "rgba(0, 0, 0, 0.6)",

    "& .MuiBackdrop-root": {
      backgroundColor: "rgb(74 74 74 / 50%)",
    },
    "& .MuiDialog-paperWidthSm": {
      background: "#0C576C",
      width: "100%",
      maxWidth: "392px",
      padding: "75px",
    },
  },
  TextBox: {
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffffff",
      borderRadius: "2.77px",
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "#ffffff !important",
    },
    "& .MuiOutlinedInput-input": {
      height: "22px",
    },
  },
  heading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "14px",
    color: "#FFFFFF",
    margin: "0",
    marginBottom: "12px",
  },
  subHeading: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    color: "rgba(255, 255, 255, 0.6)",
  },
  headingDiv: {
    textAlign: "center",
  },

  rememberMe: {
    "& .MuiTypography-body1": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "20px",
      color: "rgba(255, 255, 255, 0.6)",
    },
  },
  forgotPassword: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#D39B2D",
  },
  submitButton: {
    width: "100%",
    height: "44px",
    background: "#D39B2D",
    borderRadius: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#FFFFFF",
  },
}));
export default function Login(props) {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const theme = useTheme();
  const [isloading, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  function rememberMe() {
    if (!isRememberMe) {
      setIsRememberMe(true);
      const email = document.getElementById("email");
      const password = document.getElementById("password");
      window.sessionStorage.setItem("email", email?.value);
      window.localStorage.setItem("password", password?.value);
    } else {
      setIsRememberMe(false);
      window.sessionStorage.removeItem("email");
      window.localStorage.removeItem("password");
    }
  }
  const formInitialSchema = isRememberMe
    ? {
        email: "",
        password: "",
      }
    : {
        email: window.sessionStorage.getItem("email")
          ? window.sessionStorage.getItem("email")
          : "",
        password: window.localStorage.getItem("password")
          ? window.localStorage.getItem("password")
          : "",
      };
  const handleFormSubmit = async (values) => {
    sessionStorage.setItem("email", values.email);
    setLoader(true);
    try {
      const res = await axios.post(ApiConfig.login, {
        email: values.email,
        password: values.password,
      });
      console.log(res, "resss");
      if (res.data.responseCode === 200) {
        setLoader(false);
        window.localStorage.setItem("token", res.data.result.token);
        window.localStorage.setItem("name", res.data.result.name);
        toast.success("You are successfully logged in.");
        // auth.getProfileHandler( res.data.result.token);
        auth.userLogIn(res.data.result.token, true);
      } else if (res.data.status === 500) {
        setLoader(false);
        toast.error(
          "Cannot reach internet. Please check your device internet connections."
        );
      } else {
        toast.warn(res.data.message);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
      if (error.res) {
        toast.error("Email or Password not Correct");
      } else {
        toast.error(error.response.data.responseMessage);
      }
    }
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleLoginModal}
        aria-labelledby="responsive-dialog-title"
        className={classes.dialogMain}
      >
        <Box>
          <Logo style={{ marginBottom: "30px", width: "139px" }} />
        </Box>
        <Box className={classes.headingDiv}>
          <Typography className={classes.heading}>Hello Again!</Typography>
          <Typography className={classes.subHeading}>
            Easily buy and sell items with just a few clicks
          </Typography>
        </Box>
        <Box>
          <Formik
            onSubmit={(values) => handleFormSubmit(values)}
            initialValues={formInitialSchema}
            // initialValues={{
            //   email: "",
            //   password: "",
            // }}
            initialStatus={{
              success: false,
              successMsg: "",
            }}
            validationSchema={yep.object().shape({
              email: yep
                .string()
                .email(
                  "You have entered an invalid email address. Please try again"
                )
                .required("Please enter your email address")
                .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),

              password: yep
                .string()
                .max(16)
                .min(8, "Password must be at least 8 characters")
                .required("Please enter your password"),
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form>
                <Grid item style={{ marginTop: "1.5rem" }}>
                  <TextField
                    label="Email"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="email outlined-basic"
                    size="small"
                    inputProps={{ maxLength: 256 }}
                    value={values.email}
                    name="email"
                    className="textFeilds"
                    error={Boolean(touched.email && errors.email)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    InputProps={{
                      className: classes.TextBox,
                    }}
                  />
                  <FormHelperText
                    error
                    style={{ fontSize: "12px", fontFamily: "Roboto" }}
                  >
                    {touched.email && errors.email}
                  </FormHelperText>
                </Grid>
                <Grid item style={{ marginTop: "1.5rem" }}>
                  <FormControl fullWidth>
                    <Box
                      style={{ width: "100%" }}
                      className={classes.loginForm1}
                    >
                      <TextField
                        className={`${classes.inputvalue} textFeilds`}
                        label="Password"
                        size="small"
                        variant="outlined"
                        fullWidth
                        id="password outlined-basic"
                        type={showPassword ? "text" : "password"}
                        value={values.password}
                        inputProps={{ minLength: 8, maxLength: 16 }}
                        name="password"
                        error={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        InputLabelProps={{
                          style: { color: "#fff" },
                        }}
                        InputProps={{
                          className:
                            wrongPass === true
                              ? classes.wrongPassword
                              : classes.TextBox,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Box className={classes.passsec}>
                                  {showPassword ? (
                                    <img
                                      src="/images/Eye.png"
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        // color: "#1069C2",
                                        alignItems: "center",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="/images/Hide.png"
                                      style={{
                                        fontSize: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        // color: "#1069C2",
                                        alignItems: "center",
                                      }}
                                    />
                                  )}
                                </Box>
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText
                        error
                        style={{ fontSize: "12px", fontFamily: "Roboto" }}
                      >
                        {touched.password && errors.password}
                      </FormHelperText>
                    </Box>
                  </FormControl>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "5px" }}
                  >
                    <Box pl={1} mt={-1}>
                      <FormControlLabel
                        className={classes.rememberMe}
                        control={
                          <Checkbox
                            name="checkedC"
                            checked={isRememberMe}
                            onClick={rememberMe}
                          />
                        }
                        label="Remember me"
                      />
                    </Box>
                    <Typography>
                      <Link
                        component={RouterComponent}
                        to="/forget-password"
                        className={classes.forgotPassword}
                      >
                        Forgot Password?
                      </Link>
                    </Typography>
                  </Box>
                </Grid>

                <Grid>
                  <Box className={classes.SubmitBtnBox} mt={2}>
                    <Button
                      type="submit"
                      className={classes.submitButton}
                      disabled={isloading}
                      // onClick={() => history.push("/dashboard")}
                    >
                      Login
                      {isloading && <ButtonCircularProgress />}
                    </Button>
                  </Box>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </div>
  );
}
